import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import logo from '../../assets/images/logomaster1.png';

export const FooterContainer = styled.footer`
	background-color: #0d1117;
	height: auto;
`;

export const FooterWrap = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 100%;
	margin: 0 auto;
	min-height: auto;
`;

export const FooterLinksContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: auto;
	height: 60px;
	color: #fff;
	margin: 16px;

	@media screen and (max-width: 420px) {
		margin: 0;
		padding: 10px;
		width: 100%;
	}

	@media screen and (max-width: 768px) {
		padding-top: 32px;
	}
`;

export const FooterLink = styled(LinkS)`
	color: #fff;
	text-decoration: none;
	margin-bottom: 0.5rem;
	font-size: 14px;

	&:hover {
		color: #f2c21c;
		transition: 0.3s ease-out;
	}
`;

/* CONTACT FORM */

export const FooterFormWrap = styled.div`
	display: flex;
	flex-direction: column;
	width: auto;
	height: auto;
	margin-bottom: 40px;
`;

/* SOCIAL MEDIA */

export const SocialMedia = styled.section`
	max-width: 1000px;
	width: 100%;
`;

export const SocialMediaWrap = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	max-width: 1100px;
	margin: auto;

	/* 	@media screen and (max-width: 820px) {
		flex-direction: column;
	} */
`;

export const Footerh6 = styled.div`
	display: inline-block;
	flex-direction: row;
	font-size: 0.8rem;
	margin: 0 0.5rem 0 0;
	color: #848e9c;

	&:hover {
		color: #f2c21c;
		transition: 0.3s ease-out;
	}
`;

export const By = styled.a`
	display: inline-block;
	flex-direction: row;
	font-size: 0.8rem;
	margin: 0 0.5rem 0 0;
	color: #848e9c;

	&:hover {
		color: #f2c21c;
		transition: 0.3s ease-out;
	}
`;

export const LogoFooter = styled.div`
	background-image: url(${logo});
	background-repeat: no-repeat;
	background-size: 300px;
	height: 150px;
	width: 300px;
	margin-right: 0.5rem;
	margin-top: 1rem;
`;

export const SocialLogo = styled(Link)`
	color: #fff;
	justify-self: start;
	cursor: pointer;
	text-decoration: none;
	font-size: 1.5rem;
	display: flex;
	align-items: center;
	font-weight: bold;

	&:hover {
		color: #f2c21c;
		transition: 0.3s ease-out;
	}
`;

export const P = styled.p`
	font-size: 1rem;
	color: white;
	margin-bottom: 2.5rem;
	text-align: center;
`;

export const WebsiteRights = styled.small`
	color: #fff;
	margin: 1rem auto 0.1rem auto;
	text-align: center;
`;

export const SocialIcons = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 240px;
	color: #fff;
	font-size: 25px;
	margin-bottom: 1rem;
`;

export const SocialIconLink = styled.a`
	color: #fff;
	font-size: 24px;

	&:hover {
		color: #f2c21c;
		transition: 0.3s ease-out;
	}
`;
