import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import SignIn from '../components/Signin';

const SigninPage = () => {
	return (
		<>
			<ScrollToTop />
			<SignIn />
		</>
	);
};

export default SigninPage;
