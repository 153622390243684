import React from 'react';
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import logo from '../../assets/images/logo1ae.png';
import {
	FooterContainer,
	FooterWrap,
	LogoFooter,
	// FooterLinksContainer,
	// FooterLink,
	SocialMedia,
	SocialMediaWrap,
	Footerh6,
	By,
	// SocialLogo,
	WebsiteRights,
	SocialIcons,
	SocialIconLink,
	// P,
} from './FooterElements';
// import Contact from '../Contact';

const Footer = () => {
	const toggleHome = () => {
		scroll.scrollToTop();
	};

	return (
		<FooterContainer id='contact'>
			{/* <Contact /> */}
			<FooterWrap>
				<LogoFooter></LogoFooter>
				{/* <FooterLinksContainer> */}
				{/* 							<FooterLinkTitle>Información de utilidad:</FooterLinkTitle> */}
				{/* <FooterLink to='presentacion' smooth={true} offset={-80}>
						Presentacion
					</FooterLink>
				</FooterLinksContainer> */}
				<SocialMedia>
					<SocialMediaWrap>
						<SocialIcons>
							<SocialIconLink target='_blank' aria-label='Facebook'>
								<FaFacebook />
							</SocialIconLink>
							<SocialIconLink target='_blank' aria-label='Instagram'>
								<FaInstagram />
							</SocialIconLink>

							<SocialIconLink target='_blank' aria-label='Youtube'>
								<FaYoutube />
							</SocialIconLink>

							<SocialIconLink target='_blank' aria-label='Twitter'>
								<FaTwitter />
							</SocialIconLink>

							<SocialIconLink target='_blank' aria-label='Linkedin'>
								<FaLinkedin />
							</SocialIconLink>
						</SocialIcons>
						{/* Politics Section */}
						<section>
							<Footerh6>Terminos</Footerh6>
							<Footerh6>Privacidad</Footerh6>
							<Footerh6>Cookies</Footerh6>
						</section>
						{/* Copyright */}
						<WebsiteRights>
							Copyright © {new Date().getFullYear()} MasterCrypto todos los derechos.
						</WebsiteRights>
						{/* Created By */}
						<By
							id='footer-created-by'
							href='mailto:ezequiel.salomon@gmail.com'
							rel='noopener noreferrer'
						>
							Created by : EAS
						</By>
					</SocialMediaWrap>
				</SocialMedia>
			</FooterWrap>
		</FooterContainer>
	);
};

export default Footer;
