import React from 'react';
import {
	SidebarContainer,
	Icon,
	CloseIcon,
	SidebarWrapper,
	SidebarLink,
	SidebarMenu,
} from './SidebarElements';

const Sidebar = ({ isOpen, toggle }) => {
	return (
		<SidebarContainer isOpen={isOpen} onClick={toggle}>
			<Icon OnClick={toggle}>
				<CloseIcon />
			</Icon>
			<SidebarWrapper>
				<SidebarMenu>
					<SidebarLink to='services' offset={-80} onClick={toggle}>
						Servicios y Convenios
					</SidebarLink>
					<SidebarLink to='about' offset={-35} onClick={toggle}>
						Nosotros
					</SidebarLink>
					<SidebarLink to='contact' offset={-40} onClick={toggle}>
						Contacto
					</SidebarLink>
					{/*Botón de registrarse comentado por el momento ya que no va a usarse.
						<SidebarLink to='signup' onClick={toggle}>
							Registrarse
						</SidebarLink> */}
				</SidebarMenu>
				{/* Botón de Ingresar comentado por el momento ya que no va a usarse.
					<SideBtnWrap>
					<SidebarRoute to='/signIn'>Ingresar</SidebarRoute>
				</SideBtnWrap> */}
			</SidebarWrapper>
		</SidebarContainer>
	);
};

export default Sidebar;
