import styled from 'styled-components';
import logo from '../../assets/images/logo_fundacion.png';
import logo2 from '../../assets/images/logo_uom.png';
import img from '../../assets/images/00.jpg';

/* Contenedor Principal */
export const Container = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	background: #eeeeee;
	margin: auto;
	padding-top: 40;
`;

/* Contenedor Principal Secundario */
export const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	background: #ffffff;
	width: 80%;
	height: auto;
	border: 1px solid grey;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	margin: 0 auto 2rem auto;

	@media screen and (max-width: 812px) {
		flex-direction: column;
		justify-content: flex-start;
		width: 100%;
	}
`;

/* Imagen */
export const WrapperImg = styled.div`
	width: 40%;
	height: auto;
	background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(${img});
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: 900px;
	background-position-x: -100px;
	background-position-y: 80px;

	@media screen and (max-width: 812px) {
		width: 100%;
		min-height: 500px;
		background-size: 1000px;
		background-position-x: -185px;
		background-position-y: 0px;
	}
`;

export const WrapperContent = styled.div`
	width: 100%;
	height: 100%;
	padding-top: 3rem;
`;

export const Title = styled.div`
	width: 100%;
	align-items: center;

	@media screen and (max-width: 480px) {
		width: 100%;
		margin-bottom: 2rem;
	}
`;

export const ImgDiv = styled.div`
	display: flex;
	flex-direction: row;
	height: 230px;
	width: 90%;
	max-width: 500px;
	margin: auto;
	align-items: center;
	justify-content: center;
	margin-top: 5rem;

	@media screen and (max-width: 768px) {
		margin-bottom: 1em;
		flex-direction: column;
		width: 100%;
		height: 500px;
		margin-top: 0rem;
	}
`;

export const Img = styled.div`
	background-image: url(${logo});
	background-repeat: no-repeat;
	background-size: 150px;
	height: 180px;
	width: 150px;
	margin: auto;

	&:hover {
		transform: scale(1.02);
	}

	@media screen and (max-width: 768px) {
		margin-bottom: 0px;
	}

	@media screen and (max-width: 480px) {
		margin-bottom: 0px;
	}
`;

export const Img2 = styled.div`
	background-image: url(${logo2});
	background-repeat: no-repeat;
	background-size: 180px;
	height: 180px;
	width: 180px;
	margin: auto;

	&:hover {
		transform: scale(1.02);
	}

	@media screen and (max-width: 768px) {
		margin-bottom: 0px;
	}

	@media screen and (max-width: 480px) {
		margin-bottom: 0px;
	}
`;

export const AboutWrapperText = styled.div`
	height: auto;
	margin: auto;
	align-items: center;
	padding: 1rem;

	@media screen and (max-width: 768px) {
		width: 90%;
		padding: 0rem;
	}

	@media screen and (max-width: 480px) {
		padding: 0rem;
		margin-top: 3rem;
		width: 100%;
	}
`;

export const AboutDiv = styled.div`
	display: flex;
	flex-direction: column;
	width: 90%;
	height: auto;
	align-items: left;
	margin: auto;
	border-radius: 10px;

	@media screen and (max-width: 768px) {
		width: 100%;
		max-height: 450px;
		margin-bottom: 20px;
	}

	@media screen and (max-width: 480px) {
		width: 90%;
		max-height: 700px;
	}
`;

export const AboutH1 = styled.h1`
	font-size: 2.2rem;
	font-weight: bold;
	color: #000000;
	margin-bottom: 0.8rem;
	text-align: center;

	@media screen and (max-width: 768px) {
		font-size: 2rem;
		margin: 0px 20px 20px 0px;
		margin-left: 30px;
		text-align: center;
	}

	@media screen and (max-width: 480px) {
		font-size: 1.9rem;
		margin-top: 10px;
	}
`;

export const AboutH2 = styled.h2`
	font-size: 1.3rem;
	margin-top: 10px;
	margin-bottom: 5px;
	font-weight: 100;
	align-items: center;
	text-align: center;

	@media screen and (max-width: 480px) {
		padding-top: 10px;
		font-size: 1.2rem;
	}
`;

export const AboutH3 = styled.h3`
	font-size: 1rem;
	font-weight: 100;
	align-items: center;
	text-align: center;

	@media screen and (max-width: 480px) {
		padding-top: 10px;
	}
`;

export const AboutH4 = styled.h4`
	font-size: 1.6rem;
	margin-bottom: 20px;
	font-weight: bold;
	text-transform: uppercase;

	@media screen and (max-width: 480px) {
		font-size: 1.3rem;
	}
`;

export const AboutP = styled.p`
	font-size: 1rem;
	margin-bottom: 20px;
	text-align: justify;

	@media screen and (max-width: 768px) {
		font-size: 0.9rem;
	}

	@media screen and (max-width: 480px) {
		font-size: 0.9rem;
	}
`;
