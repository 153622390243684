import { Container, LogoBody } from './BodyElements';

const Body = () => {
	return (
		<Container>
			<LogoBody></LogoBody>
		</Container>
	);
};

export default Body;
