import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import CarouselComponent from '../components/Carousel';
import Servicies from '../components/Servicies';
import About from '../components/About';
import Body from '../components/Body';
import Form from '../components/Form';
import Footer from '../components/Footer';
import { FooterFormWrap } from '../components/Form/FormElements';

export const Home = () => {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	return (
		<>
			<Sidebar isOpen={isOpen} toggle={toggle} />
			{/* <Navbar toggle={toggle} /> */}
			{/* <CarouselComponent /> */}
			{/* <Servicies /> */}
			{/* <About /> */}
			<Body />
			<FooterFormWrap>
				<Form />
			</FooterFormWrap>
			<Footer />
		</>
	);
};

export default Home;
