import styled from 'styled-components';
import Img01 from '../../../../../assets/images/pioneros/01.png';
import Img02 from '../../../../../assets/images/pioneros/02.png';
import Img03 from '../../../../../assets/images/pioneros/03.png';
import Img04 from '../../../../../assets/images/pioneros/04.png';
import Img05 from '../../../../../assets/images/pioneros/05.png';
import Img06 from '../../../../../assets/images/pioneros/06.png';
import Img07 from '../../../../../assets/images/pioneros/07.png';
import Img08 from '../../../../../assets/images/pioneros/08.png';
import Img09 from '../../../../../assets/images/pioneros/09.png';
import Img10 from '../../../../../assets/images/pioneros/10.png';
import Img11 from '../../../../../assets/images/pioneros/11.png';
import Img12 from '../../../../../assets/images/pioneros/12.png';
import Img13 from '../../../../../assets/images/pioneros/13.png';
import Img14 from '../../../../../assets/images/pioneros/14.png';
import Img15 from '../../../../../assets/images/pioneros/15.png';
import Img104 from '../../../../../assets/images/104/104.png';
import Img104b from '../../../../../assets/images/104/104b.png';

export const Conteiner = styled.div`
	height: auto;
	color: black;
	width: 90%;
`;

export const H1 = styled.h1`
	font-size: 1.2rem;
	font-weight: 600;
	color: #072146;
	margin-bottom: 1rem;
`;

export const H3 = styled.h3`
	display: inline-block;
	font-size: 1rem;
	font-weight: 600;
	color: #072146;
	padding-left: 0.2rem;

	&:hover {
		padding-left: 1rem;
	}
`;

export const P = styled.p`
	font-size: 1rem;
	color: black;
	padding: 0 0 0.1rem 1rem;
	text-align: justify;
`;

export const ImgWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	width: 100%;
	max-width: 850px;
	height: auto;
	margin: 5rem auto 2rem auto;

	@media screen and (max-width: 940px) {
		margin: 1.5rem auto 2rem auto;
		margin-left: 0.5rem;
	}
`;

export const Img0104 = styled.div`
	display: inline-block;
	background-image: url(${Img104});
	background-repeat: no-repeat;
	background-size: cover;
	height: 200px;
	width: 400px;
	margin: 0 auto 1rem auto;
	border: 1px solid grey;

	@media screen and (max-width: 480px) {
		height: 180px;
		width: 300px;
	}
`;

export const Img0104b = styled.div`
	display: inline-block;
	background-image: url(${Img104b});
	background-repeat: no-repeat;
	background-size: cover;
	height: 200px;
	width: 400px;
	margin: 0 auto 1rem auto;
	border: 1px solid grey;

	@media screen and (max-width: 480px) {
		height: 180px;
		width: 300px;
	}
`;

export const SocialIconLink = styled.a`
	display: inline-block;
	color: grey;
	font-size: 24px;
	padding-top: 2rem;

	&:hover {
		color: #072146;
		transition: 0.1s ease-out;
	}
`;

export const Img1 = styled.div`
	display: inline-block;
	background-image: url(${Img01});
	background-repeat: no-repeat;
	background-size: cover;
	height: 155px;
	width: 155px;
	margin: 0 auto 1rem auto;
	border: 1px solid grey;
`;

export const Img2 = styled.div`
	display: inline-block;
	background-image: url(${Img02});
	background-repeat: no-repeat;
	background-size: cover;
	height: 155px;
	width: 155px;
	margin: 0 auto 0 auto;
	border: 1px solid grey;
`;

export const Img3 = styled.div`
	display: inline-block;
	background-image: url(${Img03});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 155px;
	width: 155px;
	margin: 0 auto 1rem auto;
	border: 1px solid grey;
`;

export const Img4 = styled.div`
	display: inline-block;
	background-image: url(${Img04});
	background-repeat: no-repeat;
	background-size: cover;
	height: 155px;
	width: 155px;
	margin: 0 auto 0 auto;
	border: 1px solid grey;
`;

export const Img5 = styled.div`
	display: inline-block;
	background-image: url(${Img05});
	background-repeat: no-repeat;
	background-size: cover;
	height: 155px;
	width: 155px;
	margin: 0 auto 1rem auto;
	border: 1px solid grey;
`;

export const Img6 = styled.div`
	display: inline-block;
	background-image: url(${Img06});
	background-repeat: no-repeat;
	background-size: cover;
	height: 155px;
	width: 155px;
	margin: 0 auto 0 auto;
	border: 1px solid grey;
`;

export const Img7 = styled.div`
	display: inline-block;
	background-image: url(${Img07});
	background-repeat: no-repeat;
	background-size: cover;
	height: 155px;
	width: 155px;
	margin: 0 auto 1rem auto;
	border: 1px solid grey;
`;

export const Img8 = styled.div`
	display: inline-block;
	background-image: url(${Img08});
	background-repeat: no-repeat;
	background-size: cover;
	height: 155px;
	width: 155px;
	margin: 0 auto 0 auto;
	border: 1px solid grey;
`;

export const Img9 = styled.div`
	display: inline-block;
	background-image: url(${Img09});
	background-repeat: no-repeat;
	background-size: cover;
	height: 155px;
	width: 155px;
	margin: 0 auto 1rem auto;
	border: 1px solid grey;
`;
export const Img010 = styled.div`
	display: inline-block;
	background-image: url(${Img10});
	background-repeat: no-repeat;
	background-size: cover;
	height: 155px;
	width: 155px;
	margin: 0 auto 1rem auto;
	border: 1px solid grey;
`;
export const Img011 = styled.div`
	display: inline-block;
	background-image: url(${Img11});
	background-repeat: no-repeat;
	background-size: cover;
	height: 155px;
	width: 155px;
	margin: 0 auto 1rem auto;
	border: 1px solid grey;
`;
export const Img012 = styled.div`
	display: inline-block;
	background-image: url(${Img12});
	background-repeat: no-repeat;
	background-size: cover;
	height: 155px;
	width: 155px;
	margin: 0 auto 1rem auto;
	border: 1px solid grey;
`;
export const Img013 = styled.div`
	display: inline-block;
	background-image: url(${Img13});
	background-repeat: no-repeat;
	background-size: cover;
	height: 155px;
	width: 155px;
	margin: 0 auto 1rem auto;
	border: 1px solid grey;
`;

export const Img014 = styled.div`
	display: inline-block;
	background-image: url(${Img14});
	background-repeat: no-repeat;
	background-size: cover;
	height: 155px;
	width: 155px;
	margin: 0 auto 1rem auto;
	border: 1px solid grey;
`;

export const Img015 = styled.div`
	display: inline-block;
	background-image: url(${Img15});
	background-repeat: no-repeat;
	background-size: cover;
	height: 155px;
	width: 155px;
	margin: 0 auto 1rem auto;
	border: 1px solid grey;
`;
