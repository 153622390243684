import styled from 'styled-components';
import img from '../../../../../assets/images/family.jpg';

export const Conteiner = styled.div`
	height: auto;
	width: 100%;
	color: black;
`;

export const SlaveConteiner = styled.div`
	display: flex;
	flex-direction: column;
	width: 98.5%;
	margin-bottom: 3rem;
`;

export const Img = styled.div`
	width: 100%;
	height: 500px;
	background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${img});
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: 100%;
	background-position-x: -50px;
	background-position-y: 60px;

	@media screen and (max-width: 1300px) {
		width: 100%;
		min-height: 200px;
		background-size: 1500px;
		background-position: center;
		background-position-y: 80px;
	}
`;

export const Wrapper = styled.div`
	width: 95%;
`;

export const WrappConteiner = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	height: auto;
	width: 70%;
	margin: 1em auto 0 auto;
	justify-content: flex-start;

	@media screen and (max-width: 1100px) {
		flex-direction: column;
		width: 90%;
		padding-left: 1.5rem;
	}
`;

export const Wrapp = styled.div`
	display: flex;
	flex-direction: row;
	height: auto;
	width: 100%;
	margin: auto;
	padding-left: 1rem;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 2rem;

	@media screen and (max-width: 1100px) {
		flex-direction: column;
	}
`;

export const Wrapp2 = styled.div`
	display: flex;
	flex-direction: column;
	height: auto;
	width: 40%;
	margin: auto;
	margin-bottom: 1rem;

	@media screen and (max-width: 1100px) {
		width: 100%;
		margin-bottom: 1rem;
	}
`;

export const H2 = styled.h2`
	font-size: 2rem;
	font-weight: 600;
	color: #072146;
	margin-bottom: 2rem;
`;

export const H3 = styled.h3`
	font-size: 0.9rem;
	font-weight: 600;
	color: #072146;
	margin-bottom: 1rem;
`;

export const Icon = styled.div`
	display: inline-block;
	margin: 0 5px 0 0;
	font-size: 1.4rem;
	width: auto;
	align-items: flex-start;
	height: auto;

	@media screen and (max-width: 480px) {
		font-size: 1rem;
	}
`;

export const IconAlert = styled.div`
	display: inline-block;
	color: #fff;
	font-size: 1.7rem;
	width: 10%;
	margin-left: -47px;
	margin-right: 15px;
	margin-top: 5px;

	@media screen and (max-width: 480px) {
	}
`;

export const H4 = styled.h4`
	display: inline-block;
	font-size: 0.9rem;
	color: #072146;
	padding: 0 0 0 0.5rem;
	cursor: pointer;

	&:hover {
		font-weight: 600;
		margin-left: 15px;
	}

	@media screen and (max-width: 480px) {
		font-size: 0.85rem;
	}
`;

export const P = styled.p`
	font-size: 1rem;
	color: black;
	padding: 0 0 0.1rem 1rem;
	text-align: justify;
`;
export const P1 = styled.p`
	font-size: 0.9rem;
	color: black;
	padding: 2rem 0 0.1rem 1rem;
	text-align: justify;
`;

export const P2 = styled.p`
	font-size: 0.8rem;
	color: black;
	font-weight: 600;
	margin: 0px;
`;

export const P3 = styled.p`
	font-size: 0.8rem;
	color: black;
	margin: 0px 10px 0px 10px;
`;

export const Red = styled.div`
	display: flex;
	flex-direction: row;
	border: 1px solid red;
	font-size: 0.8rem;
	background: #f8e0e2;
	border-color: #d34353;
	border-left: 40px solid #d34353;
	width: 450px;
	margin: 3rem 0 2rem 1rem;
	padding: 0.8rem;

	@media screen and (max-width: 480px) {
		margin: 3rem 0 2rem 0;
		width: 100%;
	}
`;

export const Pred = styled.div`
	font-size: 0.8rem;
	width: 90%;

	@media screen and (max-width: 480px) {
		margin-left: 10px;
	}
`;

/* #WRAP */
export const Place = styled.p`
	font-size: 0.9rem;
	font-weight: 600;
	margin: 0;
	width: 100%;
`;

export const Description = styled.p`
	font-size: 0.9rem;
	margin: 0;
	width: 100%;
`;

export const Price = styled.p`
	font-size: 1.1rem;
	margin: 5px 0 10px 0;
	width: 100%;
`;
