import styled from 'styled-components';

import logo from '../../assets/images/logomaster.png';

export const Container = styled.div`
	background-color: #eeeeee;
	margin: auto;
	padding-top: 2rem;
`;

export const LogoBody = styled.div`
	background-image: url(${logo});
	background-repeat: no-repeat;
	background-size: 300px;
	height: 150px;
	width: 300px;
	margin: auto;
`;
