import styled from 'styled-components';
import image1 from './../../assets/images/1.jpg';
import image2 from './../../assets/images/2.jpg';
import image3 from './../../assets/images/3.jpg';
import image4 from './../../assets/images/4.jpg';
import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md';

export const Container = styled.div`
	max-width: auto;
	background-color: #000;
	border: 1px solid black;
`;

export const Img1 = styled.div`
	background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${image1});
	height: auto;
	width: auto;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	position: relative;
	padding: 23rem;

	@media screen and (max-width: 768px) {
		padding: 12rem;
	}

	@media screen and (max-width: 480px) {
		padding: 10rem;
	}
`;

export const Img2 = styled.div`
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)), url(${image2});
	height: auto;
	width: auto;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	position: relative;
	padding: 23rem;

	@media screen and (max-width: 768px) {
		padding: 12rem;
	}

	@media screen and (max-width: 480px) {
		padding: 10rem;
	}
`;

export const Img3 = styled.div`
	background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url(${image3});
	height: auto;
	width: auto;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	position: relative;
	padding: 23rem;

	@media screen and (max-width: 768px) {
		padding: 12rem;
	}

	@media screen and (max-width: 480px) {
		padding: 10rem;
	}
`;

export const Img4 = styled.div`
	background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4)), url(${image4});
	height: auto;
	width: auto;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	position: relative;
	padding: 23rem;

	@media screen and (max-width: 768px) {
		padding: 12rem;
	}

	@media screen and (max-width: 480px) {
		padding: 10rem;
	}
`;

export const H3 = styled.h1`
	color: #fff;
	font-size: 35px;
	text-shadow: 2px 2px #000;
	text-transform: uppercase;

	@media screen and (max-width: 768px) {
		font-size: 34px;
	}

	@media screen and (max-width: 480px) {
		font-size: 26px;
	}
`;

export const P = styled.p`
	color: #fff;
	font-size: 22px;
	text-shadow: 2px 2px #000;

	@media screen and (max-width: 768px) {
		font-size: 18px;
	}

	@media screen and (max-width: 480px) {
		font-size: 16px;
	}
`;
export const P2 = styled.p`
	font-size: 14px;
	margin-top: -1rem;

	@media screen and (max-width: 768px) {
		font-size: 16px;
	}

	@media screen and (max-width: 480px) {
		font-size: 14px;
	}
`;

export const BtnWrapper = styled.div`
	margin-top: 18px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
	margin-left: 8px;
	font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
	margin-left: 8px;
	font-size: 20px;
`;
