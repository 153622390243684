import React from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import './FormElements.css';

const FormSuccess = () => {
	return (
		<dir className='form-content-success1'>
			<div className='form-content-success'>
				<span className='form-success-icon'>
					<AiOutlineCheckCircle />
				</span>
				<h1 className='form-success'>
					Su consulta fue enviada correctamente! Nos comunicaremos a la brevedad.
				</h1>
			</div>
		</dir>
	);
};

export default FormSuccess;
