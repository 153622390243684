import styled from 'styled-components';

/* CONTACT FORM */

export const FooterFormWrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: auto;
	height: auto;
	background: #eeeeee;
	padding-bottom: 40px;
`;
