import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';

export const Conteiner = styled.div`
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #eeeeee;
	margin: auto;

	@media screen and (max-width: 768px) {
		height: auto;
	}

	@media screen and (max-width: 480px) {
		height: auto;
		padding: 0px 0px 30px 0px;
	}
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid grey;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	background: #ffffff;
	width: 80%;
	margin: 40px auto 40px auto;
	padding: 2rem 2rem 3rem 2rem;
	align-items: center;
	height: auto;

	@media screen and (max-width: 768px) {
		width: 100%;
		margin: 0px auto 40px auto;
	}

	@media screen and (max-width: 480px) {
		width: 100%;
		margin: 0px auto 40px auto;
		padding: 2rem 1.5rem 3rem 1.5rem;
	}
`;

export const DivH1 = styled.div`
	width: 100%;
	margin-bottom: 2rem;
`;

export const DivsWrapper = styled.div`
	width: 100%;
	max-width: 700px;
	margin: 0 auto 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-left: 2rem;

	@media screen and (max-width: 768px) {
		width: 100%;
		padding-left: 0;
	}

	@media screen and (max-width: 700px) {
		width: 100%;
		flex-direction: column;
	}
`;

export const Div1 = styled.div`
	display: flex;
	flex-direction: column;
	height: auto;
	width: 50%;
	align-items: center;

	@media screen and (max-width: 768px) {
		width: 100%;
		min-height: 200px;
	}

	@media screen and (max-width: 480px) {
		width: 100%;
		min-height: 200px;
		margin-bottom: 40px;
	}
`;

export const Div2 = styled.div`
	display: flex;
	flex-direction: column;
	height: auto;
	width: 50%;
	align-items: center;

	@media screen and (max-width: 768px) {
		width: 100%;
		min-height: 200px;
		margin-bottom: 20px;
	}

	@media screen and (max-width: 480px) {
		width: 100%;
		min-height: 200px;
	}
`;

export const H1 = styled.h1`
	font-size: 1.5rem;
	text-transform: uppercase;
	font-weight: bold;
	color: #000000;
	width: 100%;
	align-items: center;
	text-align: center;
	margin: auto;

	@media screen and (max-width: 768px) {
		font-size: 1.5rem;
	}

	@media screen and (max-width: 480px) {
		font-size: 1.2rem;
		width: 90%;
	}
`;

export const H2 = styled.h2`
	font-size: 1.25rem;
	width: 85%;
	margin-top: 20px;
	margin-bottom: 40px;
	align-items: center;
	font-weight: bold;
	text-transform: uppercase;

	@media screen and (max-width: 768px) {
		padding-top: 10px;
		width: 90%;
	}

	@media screen and (max-width: 480px) {
		padding-top: 10px;
		width: 90%;
	}
`;

export const P = styled(LinkS)`
	font-size: 0.8rem;
	margin: 5px auto 0px 25px;
	text-transform: uppercase;
	color: black;

	&:hover {
		cursor: pointer;
		color: #00003c;
		font-weight: 600;
	}

	@media screen and (max-width: 768px) {
		font-size: 0.8rem;
		margin: 10px auto 0px 20px;
	}

	@media screen and (max-width: 480px) {
		font-size: 0.8rem;
	}
`;
