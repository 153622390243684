import styled from 'styled-components';
import logo from '../../../../../assets/images/logo_CFL.png';
import CFL from '../../../../../assets/images/CFL.png';

export const Conteiner = styled.div`
	height: auto;
	color: black;
	width: 95%;
`;

export const H2 = styled.h2`
	font-size: 1.2rem;
	font-weight: 600;
	color: #072146;
	margin: 0rem 0 1rem 0;
`;

export const P = styled.p`
	display: block;
	font-size: 1rem;
	color: black;
	padding: 0 0 2rem 1rem;
`;

export const WrappPc = styled.div`
	margin-bottom: 2rem;
`;

export const Pc = styled.p`
	font-size: 0.85rem;
	color: black;
	padding: 0rem 0 0 1rem;
	margin-bottom: 0.25rem;
`;

export const Img = styled.div`
	display: inline-block;
	background-image: url(${logo});
	background-repeat: no-repeat;
	background-size: cover;
	height: 150px;
	width: 300px;
	margin: 0 auto 0 2rem;
`;

export const ImgIframe = styled.div`
	display: flex;
	justify-content: space-evenly;
	flex-direction: row;
	width: 100%;
	max-width: 750px;
	margin: 2rem 0 2rem 0;

	@media screen and (max-width: 375px) {
		margin-left: -1rem;
	}

	@media screen and (max-width: 1000px) {
		flex-direction: column;
	}
`;

export const Img2 = styled.div`
	background-image: url(${CFL});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 340px;
	height: 270px;
	border: 1px solid grey;

	@media screen and (max-width: 1000px) {
		width: 335px;
		margin-left: 1rem;
	}
`;

export const Iframe = styled.iframe`
	align-items: center;
	width: 340px;
	height: 270px;
	border: 1px solid grey;
	margin: 0 0 1rem 1rem;

	@media screen and (max-width: 1000px) {
		width: 335px;
		margin-top: 1rem;
	}
`;

export const Icon = styled.div`
	display: inline-block;
	margin: 2rem 1rem 0 0;
	font-size: 1.4rem;
	width: auto;
	align-items: flex-start;
	height: auto;

	@media screen and (max-width: 480px) {
		font-size: 1rem;
	}
`;

export const Link = styled.a`
	cursor: pointer;
	color: #072146;
	font-size: 1.5rem;
	padding: 2rem 0 0 0.5rem;

	&:hover {
		font-weight: 600;
		margin-left: 1rem;
		color: #072146;
	}
`;
