import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import logo from '../../assets/images/logo_navbar.png';

export const Nav = styled.nav`
	background: ${({ scrollNav }) => (scrollNav ? '#0d1117' : 'transparent')};
	height: 75px;
	margin-top: -80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	position: sticky;
	top: 0;
	z-index: 100 !important;

	@media screen and (min-width: 960px) {
		transition: 0.8s all ease;
	}
`;

export const NavbarContainer = styled.div`
	display: flex;
	justify-content: space-between;
	height: 80px;
	z-index: 1;
	width: 90%;
	padding: 0px;
	max-width: 1100px;

	@media screen and (max-width: 480px) {
		width: 100%;
	}
`;

export const LogoFunda = styled.div`
	background-image: url(${logo});
	background-repeat: no-repeat;
	background-size: 120px;
	height: 70px;
	width: 120px;
	margin-right: 0.5rem;
	margin-top: 0.1rem;
`;

export const NavLogo = styled(LinkR)`
	color: #fff;
	text-shadow: 1px 1px #000;
	justify-self: flex-start;
	cursor: pointer;
	font-size: 1.5rem;
	display: flex;
	align-items: center;
	margin-top: -1px;
	font-weight: bold;
	text-decoration: none;

	&:hover {
		color: #fff !important;
	}
`;

export const LogoConteiner = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 30%;

	@media screen and (max-width: 768px) {
		width: 300px;
	}
`;

export const LogoContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
	padding-top: 0.5rem;
`;

export const SubLogo = styled.h3`
	color: white;
	font-size: 0.8rem;
	width: 150px;
`;

export const MobileIcon = styled.div`
	display: none;

	@media screen and (max-width: 768px) {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 35%);
		font-size: 1.8rem;
		cursor: pointer;
		color: #fff;
	}
`;
export const NavMenu = styled.ul`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	list-style: none;
	text-align: center;
	width: 100%;
	height: 100%;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

export const NavItem = styled.li`
	height: 75px;
`;

export const NavLinks = styled(LinkS)`
	color: #d2d2d2;
	text-shadow: 1px 1px #000;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0 1rem;
	height: 100%;
	cursor: pointer;
	margin-top: 0px;
	font-size: 18px;

	&:hover {
		color: #fff !important;
	}

	&.active {
		color: #fff;
		border-bottom: 6px solid #f2c21c;
	}
`;
export const NavBtn = styled.nav`
	display: flex;
	align-items: center;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;
export const NavBtnLink = styled(LinkR)`
	border-radius: 50px;
	background: #01bf71;
	white-space: nowrap;
	padding: 10px 22px;
	color: #010606;
	margin-top: 4px;
	font-size: 16px;
	outline: none;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;

	&:hover {
		transition: all 0.2s ease-in-out;
		background: #fff;
		color: #010606;
	}
`;
