import { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';

const useForm = (callback, validate) => {
	const [values, setValues] = useState({
		name: '',
		email: '',
		phone: '',
		text: '',
	});
	const [errors, setErrors] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [event, setEvent] = useState(null);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setEvent(e);
		setErrors(validate(values));
		setIsSubmitting(true);
	};

	const sendEmail = (e) => {
		emailjs
			.sendForm('service_ik4lxc5', 'template_s8bl5te', e.target, 'user_kyd9VdqNn4q3U5CYqHAxE')
			.then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
	};

	useEffect(() => {
		if (Object.keys(errors).length === 0 && isSubmitting) {
			callback();
			sendEmail(event);
		}
	}, [errors]);

	return { handleChange, handleSubmit, values, errors };
};

export default useForm;
