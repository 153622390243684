import styled from 'styled-components';
import amfa from '../../../../../../assets/images/amfa.png';

export const Conteiner = styled.div`
	height: auto;
	color: black;
	width: 95%;
`;

export const H2 = styled.h2`
	font-size: 1.2rem;
	font-weight: 600;
	color: #072146;
	margin-bottom: 1rem;
`;

export const P = styled.p`
	font-size: 1rem;
	color: black;
	padding: 0 0 0rem 1rem;
	margin: 0.2rem;
`;

export const Wrapper = styled.div`
	margin-bottom: 2rem;
`;
export const Wrapper2 = styled.div`
	display: flex;
	flex-direction: row;
	margin: 2rem auto 2rem auto;

	@media screen and (max-width: 1365px) {
		flex-direction: column;
	}
`;
export const WrapperDiv1 = styled.div`
	margin-bottom: 2rem;
	width: 50%;

	@media screen and (max-width: 1365px) {
		width: 100%;
	}
`;
export const WrapperDiv2 = styled.div`
	margin-bottom: 2rem;
	width: 50%;

	@media screen and (max-width: 1365px) {
		width: 100%;
	}
`;

export const P2 = styled.p`
	font-size: 0.8rem;
	color: black;
	margin: 0rem;

	@media screen and (max-width: 480px) {
		font-size: 0.8rem;
	}
`;

export const Img = styled.div`
	background-image: url(${amfa});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 500px;
	height: 750px;
	margin: auto;

	@media screen and (max-width: 520px) {
		width: 330px;
		height: 520px;
		border: 2px solid grey;
	}
`;

export const ListConteiner = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	padding-left: 1rem;
	margin-bottom: 2rem;

	@media screen and (max-width: 480px) {
		width: 100%;
		padding-left: 0.5rem;
	}
`;

export const ListLeft = styled.div`
	display: flex;
	flex-direction: column;
	width: 70%;

	@media screen and (max-width: 480px) {
		width: 75%;
	}
`;

export const ListRight = styled.div`
	width: 30%;

	@media screen and (max-width: 480px) {
		width: 25%;
	}
`;
