import styled from 'styled-components';
import imgBCR from '../../../../../assets/images/estudioBCR.png';

export const Conteiner = styled.div`
	height: auto;
	color: black;
	width: 95%;
`;

export const H1 = styled.h1`
	font-size: 1.2rem;
	font-weight: 600;
	color: #072146;
	margin-bottom: 1rem;
`;

export const H3 = styled.h3`
	display: inline-block;
	font-size: 1rem;
	font-weight: 600;
	color: #072146;
	padding-left: 0.2rem;

	&:hover {
		padding-left: 1rem;
	}
`;

export const P = styled.p`
	font-size: 1rem;
	color: black;
	padding: 0 0 0.1rem 1rem;
`;

export const SocialIconLink = styled.a`
	display: inline-block;
	color: grey;
	font-size: 24px;
	padding-top: 1npmrem;

	&:hover {
		color: #072146;
		transition: 0.1s ease-out;
	}
`;

export const ImgBCR = styled.div`
	display: block;
	background-image: url(${imgBCR});
	background-repeat: no-repeat;
	background-size: cover;
	height: 155px;
	width: 370px;
	margin-top: 3rem;
	margin-bottom: 2rem;
	border: 1px solid grey;

	@media screen and (max-width: 375px) {
		height: 138px;
		width: 330px;
	}
`;
