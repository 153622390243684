import styled from 'styled-components';

export const Conteiner = styled.div`
	height: auto;
	color: black;
	width: 95%;
`;

export const H1 = styled.h1`
	font-size: 1.2rem;
	font-weight: 600;
	color: #072146;
	margin-bottom: 1rem;
`;

export const P = styled.p`
	font-size: 1rem;
	color: black;
	padding: 0 0 0.1rem 1rem;
`;
