import styled from 'styled-components';
import Img01 from '../../../../../../assets/images/san_cristobal.png';

export const Conteiner = styled.div`
	height: auto;
	color: black;
`;

export const H1 = styled.h1`
	font-size: 1.2rem;
	font-weight: 600;
	color: #072146;
	margin-bottom: 1rem;
`;

export const P = styled.p`
	font-size: 1rem;
	color: black;
	padding: 0 0 0.1rem 1rem;
`;

export const ImgWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	width: 90%;
	height: auto;
	margin: 3rem auto 2rem auto;

	@media screen and (max-width: 940px) {
		margin: 0.5rem auto 2rem auto;
	}
`;

export const Img1 = styled.div`
	display: inline-block;
	background-image: url(${Img01});
	background-repeat: no-repeat;
	background-size: cover;
	height: 800px;
	width: 800px;
	margin: 0 auto 1rem auto;
	border: 1px solid grey;

	@media screen and (max-width: 1024px) {
		height: 750px;
		width: 750px;
	}

	@media screen and (max-width: 812px) {
		max-height: 440px;
		max-width: 440px;
	}

	@media screen and (max-width: 480px) {
		height: 330px;
		width: 330px;
	}
`;
