import styled from 'styled-components';

export const Conteiner = styled.div`
	height: auto;
	color: black;
`;

export const H1 = styled.h1`
	font-size: 1.2rem;
	font-weight: 600;
	color: #072146;
	margin-bottom: 1rem;
`;

export const H3 = styled.h3`
	display: inline-block;
	font-size: 1rem;
	font-weight: 600;
	color: #072146;
	padding-left: 0.2rem;

	&:hover {
		padding-left: 1rem;
	}
`;

export const P = styled.p`
	font-size: 1rem;
	color: black;
	padding: 0 0 0.1rem 1rem;
`;

export const SocialIconLink = styled.a`
	display: inline-block;
	color: grey;
	font-size: 24px;
	padding-top: 1rem;

	&:hover {
		color: #072146;
		transition: 0.1s ease-out;
	}
`;
