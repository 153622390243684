import React from 'react';
import validate from './validateInfo';
import useForm from './useForm';
import './FormElements.css';

const FormSignup = ({ submitForm }) => {
	const { handleChange, handleSubmit, values, errors } = useForm(submitForm, validate);

	const formSend = (e) => {
		e.preventDefault();
		handleSubmit(e);
	};

	return (
		<div className='form-content-signup'>
			<div className='form-content'></div>
			<form onSubmit={formSend} className='form' noValidate>
				<h1>ACCEDER AL CLUB DE CRIPTOMONEDAS</h1>

				{/* # NOMBRE Y APELLIDO */}

				<div className='form-inputs'>
					<label for='name' className='form-label'>
						Nombre y Apellido:
					</label>
					<input
						id='name'
						className='form-input'
						type='text'
						name='name'
						placeholder='Ejemplo: Jose Rodriguez'
						value={values.name}
						onChange={handleChange}
					/>
					{errors.name && <p>{errors.name}</p>}
				</div>

				{/* #EMAIL */}

				<div className='form-inputs'>
					<label for='email' className='form-label'>
						Email:
					</label>
					<input
						id='email'
						className='form-input'
						type='email'
						name='email'
						placeholder='Ejemplo: correo@mail.com.ar'
						value={values.email}
						onChange={handleChange}
					/>
					{errors.email && <p>{errors.email}</p>}
				</div>

				{/* #WHATSAPP */}

				<div className='form-inputs'>
					<label for='phone' className='form-label'>
						Teléfono:
					</label>
					<input
						id='phone'
						className='form-input'
						type='text'
						name='phone'
						placeholder='Ejemplo: 3489123456'
						value={values.phone}
						onChange={handleChange}
					/>
					{errors.phone && <p>{errors.phone}</p>}
				</div>

				{/* # PAIS */}

				<div className='form-inputs'>
					<label for='pais' className='form-label'>
						¿Desde que país me escribes?:
					</label>
					<input
						id='pais'
						className='form-input'
						type='text'
						name='pais'
						placeholder='Ejemplo: Jose Rodriguez'
						value={values.pais}
						onChange={handleChange}
					/>
					{errors.pais && <p>{errors.pais}</p>}
				</div>

				<div className='qdiv'>
					{/* #1 PREGUNTA DEL CUESTIONARIO  */}

					<div className='form-inputs'>
						<div className='questions'>
							<label for='q1' className='form-label'>
								¿Qué objetivo te gustaría conseguir con tus finanzas gracias a las
								criptomonedas?:
							</label>

							<select className='questions-css' id='q1' name='q1' required>
								{/* <option disabled selected>
								Selecciona una opción
							</option> */}
								<option value={values.q1} onChange={handleChange}>
									Generar entre 500 usd y 1000 usd de ingresos cada mes.
								</option>
								<option value={values.q1} onChange={handleChange}>
									Generar entre 1000 usd y 2000 usd de ingresos cada mes.
								</option>
								<option value={values.q1} onChange={handleChange}>
									Generar más de 2000 usd de ingresos cada mes.
								</option>
								<option value={values.q1} onChange={handleChange}>
									No lo tengo claro.
								</option>
							</select>

							{/* {errors.q1 && <p>{errors.q1}</p>} */}
						</div>
					</div>

					{/* #2 PREGUNTA DEL CUESTIONARIO  */}

					<div className='form-inputs'>
						<div className='questions'>
							<label for='q2' className='form-label'>
								¿Has realizado algún tipo de inversión en los últimos 5 años?:
							</label>

							<select className='questions-css' id='q2' name='q2' required>
								{/* <option disabled selected>
								Selecciona una opción
							</option> */}
								<option value={values.q2} onChange={handleChange}>
									Menos de 500 usd.{' '}
								</option>
								<option value={values.q2} onChange={handleChange}>
									Entre 500 usd y 1000 usd.
								</option>
								<option value={values.q2} onChange={handleChange}>
									Entre 1000 usd y 3000 usd.
								</option>
								<option value={values.q2} onChange={handleChange}>
									Más de 3000 usd.
								</option>
								<option value={values.q2} onChange={handleChange}>
									No he realizado ninguna inversión.
								</option>
							</select>

							{/* {errors.q2 && <p>{errors.q2}</p>} */}
						</div>
					</div>

					{/* #3 PREGUNTA DEL CUESTIONARIO  */}

					<div className='form-inputs'>
						<div className='questions'>
							<label for='q3' className='form-label'>
								¿Qué tipo de inversión has realizado hasta ahora?:
							</label>

							<select className='questions-css' id='q3' name='q3' required>
								{/* <option disabled selected>
								Selecciona una opción
							</option> */}
								<option value={values.q3} onChange={handleChange}>
									Fondos de inversión en el banco.
								</option>
								<option value={values.q3} onChange={handleChange}>
									Inversión en bolsa.
								</option>
								<option value={values.q3} onChange={handleChange}>
									Plataformas de trading.
								</option>
								<option value={values.q3} onChange={handleChange}>
									Criptomonedas esperando que aumente su valor.
								</option>
								<option value={values.q3} onChange={handleChange}>
									Criptomonedas con estrategias de inversión defi, yield farming o staking.
								</option>
								<option value={values.q3} onChange={handleChange}>
									No he realizado ninguna inversión, aunque tengo capital para invertir.
								</option>
								<option value={values.q3} onChange={handleChange}>
									No he realizado ninguna inversión.
								</option>
							</select>

							{/* {errors.q3 && <p>{errors.q3}</p>} */}
						</div>
					</div>

					{/* #4 PREGUNTA DEL CUESTIONARIO  */}

					<div className='form-inputs'>
						<div className='questions'>
							<label for='q4' className='form-label'>
								¿Te encuentras en un momento que puedes y te apetece invertir en
								criptomonedas para hacer crecer tus finanzas con estrategias que muy poca
								gente conoce?:
							</label>

							<select className='questions-css' id='q4' name='q4' required>
								{/* <option disabled selected>
								Selecciona una opción
							</option> */}
								<option value={values.q4} onChange={handleChange}>
									Si, puedo y me apetece.
								</option>
								<option value={values.q4} onChange={handleChange}>
									Me quiero, aunque ahora no puedo.
								</option>
								<option value={values.q4} onChange={handleChange}>
									Me apetece, aunque ahora no puedo.
								</option>
							</select>

							{/* {errors.q4 && <p>{errors.q4}</p>} */}
						</div>
					</div>
				</div>

				<button className='form-input-btn' type='submit'>
					Enviar!
				</button>
				{/* 				{!isSubmitted ? (
					<FormSignup submitForm={submitForm} />
				) : (
					<FormSuccess />
				)} */}
			</form>
		</div>
	);
};

export default FormSignup;
