import styled from 'styled-components';

export const ItemContact = styled.div`
	display: flex;
	flex-direction: column;
	width: 95%;
	height: auto;
	padding: 10px;
	margin-bottom: 20px;
	padding-left: 1rem;
	margin: auto;
`;

export const ItemContactDiv1 = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: auto;
`;

export const ItemContactDiv2 = styled.div`
	width: 100%;
	height: auto;
`;

export const ItemDivWrapper = styled.div`
	display: flex;
	flex-direction: row;
	height: auto;
`;

export const Icon = styled.div`
	display: flex;
	margin: 0 10px 0 0;
	font-size: 1.4rem;
	width: auto;
	align-items: flex-start;
	height: auto;
`;

export const Bolt = styled.span`
	display: block;
	font-size: 0.9rem;
	font-weight: 600;
	height: auto;

	@media screen and (max-width: 480px) {
		font-size: 0.8rem;
	}
`;

export const ItemP = styled.p`
	display: block;
	font-size: 0.9rem;
	width: 100%;
	height: auto;
	color: black;
	padding-left: 5px;
	align-items: center;
	a {
		color: black;
		&:hover {
			color: #072146;
			font-weight: 600;
		}
	}

	@media screen and (max-width: 480px) {
		font-size: 0.8rem;
	}
`;
