export default function validateInfo(values) {
	let errors = {};

	if (!values.name) {
		errors.name = 'Su nombre completo es requerido';
	} else if (
		!/^([A-Z-a-zñáéíóú]+[\s]+[A-Z-a-zñáéíóú]*)+$/.test(values.name)
		// !/^([A-Z]{1}[a-zñáéíóú]+[\s]+[A-Z]{1}[a-zñáéíóú]*)+$/.test(values.name)
	) {
		errors.name = 'Introduzca un nombre valido';
	}

	if (!values.email) {
		errors.email = 'Su correo electrónico es requerido';
	} else if (!/\S+@\S+.\S+/.test(values.email)) {
		errors.email = 'Correo electrónico invalido!';
	}

	if (!values.phone) {
		errors.phone = 'Su teléfono es requerido';
	} else if (!/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,10}$/im.test(values.phone)) {
		errors.phone = 'Introduzca un número de teléfono valido';
	}

	// if (!values.q1) {
	// 	errors.q1 = 'Su consulta es requerida';
	// }

	if (!values.pais) {
		errors.pais = 'Su país es requerido';
	}
	return errors;
}
