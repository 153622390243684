import styled from 'styled-components';

export const Conteiner = styled.div`
	height: auto;
	color: black;
`;

export const H1 = styled.h1`
	font-size: 1.2rem;
	font-weight: 600;
	color: #072146;
	border-bottom: 0.5px;
`;

export const P = styled.p`
	font-size: 1rem;
	color: black;
	padding: 0 0 0.1rem 1rem;
`;

export const Construc = styled.div`
	border: 1px solid grey;
	width: 380px;
	margin: 5rem auto 0rem auto;
	padding: 1rem;
`;
