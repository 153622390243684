import styled from 'styled-components';

export const ItemContainer = styled.div`
	width: 80%;
	height: auto;
	margin: 0px 0 50px 0;
	border: 1px solid grey;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	border-left: 10px solid #072146;
	background: #ffffff;

	@media screen and (max-width: 768px) {
		width: 100%;
	}

	@media screen and (max-width: 480px) {
		width: 100%;
	}
`;

export const ItemTitle = styled.h2`
	font-size: 1.25rem;
	width: 100%;
	color: white;
	padding: 1rem;
	margin-bottom: 0px;
	padding-left: 1.5rem;
	align-items: center;
	font-weight: bold;
	text-transform: uppercase;
	background: linear-gradient(90deg, rgb(7, 33, 70) 1%, rgb(10, 56, 122) 50%);
`;

export const ItemTitle2 = styled.h2`
	font-size: 1.25rem;
	width: 100%;
	padding: 1rem;
	margin-bottom: -20px;
	padding-left: 1.5rem;
	align-items: center;
	font-weight: bold;
	text-transform: uppercase;
`;

export const ItemWrapper = styled.div`
	width: 100%;
	height: auto;
	margin-bottom: 50px;
	padding: 2rem 0 0 1rem;
`;
